<template>
  <div>
    <div class="right-area-container">
      <!--    描述区域-->
      <div class="desc">
        <div class="left">
          <div class="left-text">成为服务机构，您可以：</div>
          <div class="left-bar"></div>
        </div>
        <div class="right">
          <div class="img-item">
            <div class="img-box">
              <img src="~@/assets/pc/images/organization/1.png" alt="">
            </div>
            <span>维护客户资料</span>
          </div>
          <div class="img-item">
            <div class="img-box">
              <img src="~@/assets/pc/images/organization/2.png" alt="">
            </div>
            <span>匹配客户项目</span>
          </div>
          <div class="img-item">
            <div class="img-box">
              <img src="~@/assets/pc/images/organization/3.png" alt="">
            </div>
            <span>促进申报业务</span>
          </div>
          <div class="img-item">
            <div class="img-box">
              <img src="~@/assets/pc/images/organization/4.png" alt="">
            </div>
            <span>管理业务人员</span>
          </div>
        </div>
        <div class="back-img">
          <img src="~@/assets/pc/images/organization/steponebg.png" alt="">
        </div>
      </div>
      <!-- 页面内容 START -->
      <div class="right-area">
        <div class="flex col mar15" style="line-height: 2; ">
          <div class="test" style="text-align: center; font-size: 24px; margin-top:38px; color: #343434">申报通和服务机构赋能系统账号服务协议</div>
          <div class="test" style="text-align: center; margin-top: 0; color: #8D8D8D">编号：SBT-JG-FS-2021.8.1</div>
          <div class="test" style="text-indent:40px; ">欢迎您使用我们的平台！本申报通和服务机构赋能系统账号服务协议（下称“本协议”）是您在使用政策服务云平台（www.po-o.cn）服务机构赋能系统时所应遵守的条款及条件。本协议由广东政沣云技术有限公司（下称“政沣公司”）与您所代表的实体（下称“用户”或“您”）签订。您向我们声明并保证，您具有合法代表该实体之权限且有可约束该实体遵守本协议条款与条件之合法权限。本协议自您接受之日起生效，请您务必审慎阅读、充分理解本协议内容。如您对本协议有任何疑问，可咨询客服。如您不同意本协议中任何条款，请停止注册。</div>
          <div class="test" style="text-indent:40px;">同意本协议代表您同意采购申报通和服务机构赋能系统账号服务，用于挖掘客户潜力、开展增值服务。为明确有关事宜，订立合同内容如下。</div>
          <div class="flex col al-start test">
            <div style="font-weight: bold;">第一条&nbsp;&nbsp;有关名词术语释义如下</div>
            <div>（一）<i>申报通</i>：是指申报通|政策服务云平台（www.po-o.cn，含网站、微信等终端）。主要包括【政策文库】【政策词典】【项目匹配】【智能推送】【申报管家】等功能模块。</div>
            <div>（二）<i>服务机构赋能系统</i>：是指申报通供服务机构专用的一种赋能型软件和数据服务子系统。服务机构是指咨询、专利、审计、认证、会展、软件、人才等方面服务提供商。</div>
            <div>（三）<i>企业用账号</i>：是指企事业单位操作使用申报通的权限。企业用账号分为普通用户、会员，账号费的收费标准见申报通网站。释义如下：</div>
            <div style="text-indent:40px;">1．<i>普通用户</i>：是指注册开通申报通部分功能的免费用户。</div>
            <div style="text-indent:40px;">2．<i>会员</i>：是指注册开通申报通全部功能的付费用户</div>
            <div>（四）<i>机构用账号</i>：是指服务机构操作使用申报通和服务机构赋能系统的权限。机构用账号分为管理员账号、业务员账号、准账号。释义如下：</div>
            <div style="text-indent:40px;">1．<i>管理员账号</i>：归机构管理人员使用。主要用于客户管理、业务员管理、结算管理等。</div>
            <div style="text-indent:40px;">2．<i>业务员账号</i>：归机构业务员（客户服务或销售人员）使用，用于操作准账号。</div>
            <div style="text-indent:40px;">3．<i>准账号</i>：机构业务员为客户提供数据处理、项目匹配、信息发送等一系列功能的统称。一家客户配一个准账号。不同客户数据，录入不同准账号。</div>
            <div>（五）<i>项目</i>：是指政策文件中列明的扶持项目。</div>
          </div>
          <div class="flex col al-start test">
            <div style="font-weight: bold;">第二条&nbsp;&nbsp;机构用账号的组成、数量、开通办法、有效期、收费标准</div>
            <div>（一）一套机构用账号包括管理员账号1个、业务员账号若干、准账号若干。</div>
            <div>（二）管理员账号由政沣公司开通，有效期为长期，免费。</div>
            <div>（三）业务员账号由您自主开通，有效期为长期，免费。</div>
            <div>（四）准账号由您管理员开通，默认有效期为1年（365天|自开通或续费之日起算），收费标准见申报通和服务机构赋能系统。</div>
          </div>
          <div class="flex col al-start test">
            <div style="font-weight: bold;">第三条&nbsp;&nbsp;企业账号结算</div>
            <div>如您向客户销售企业用账号，客户缴纳的账号费的80%归您所有。每月结算一次。</div>
          </div>
          <div class="flex col al-start test">
            <div style="font-weight: bold;">第四条&nbsp;&nbsp;您负责的主要事项</div>
            <div>（一）指定现登录平台的手机号码为服务机构账号，用于管理员账号注册和接收消息、验证码、公告或通知等。</div>
            <div>（二）提供真实有效的证照（营业执照或法人登记证的复印件/扫描件）用于开通管理员账号。</div>
          </div>
          <div class="flex col al-start test">
            <div style="font-weight: bold;">第五条&nbsp;&nbsp;政沣公司负责的主要事项</div>
            <div>（一）政沣公司在收到您申请资料并审核无误后，开通管理员账号给您。</div>
            <div>（二）通过演示、电话、QQ等方式辅导您学会申报通和服务机构赋能系统操作。</div>
            <div>（三）通过在线客服、客服热线（4006581139）等方式为您提供软件操作咨询、报障、投诉等服务。服务时间为国家法定工作日上午8:30-12:00和下午13:30-17:30。如出现系统故障，政沣公司承诺在接到您报障后2个工作日内处理完毕。</div>
          </div>
          <div class="flex col al-start test">
            <div style="font-weight: bold;">第六条&nbsp;&nbsp;保密约定</div>
            <div>双方对本合同内容和对方商业秘密均有保密义务。未事先取得对方书面同意，不得以任何形式向任何第三方披露，否则应承担因泄密所引发的法律后果和相关责任。</div>
          </div>
          <div class="flex col al-start test">
            <div style="font-weight: bold;">第七条&nbsp;&nbsp;违约责任</div>
            <div>（一）双方均应自觉执行本合同，如未按合同约定办理有关事项，应承担相应违约责任。双方一致同意遵守《政策服务云平台用户协议》（详见申报通和服务机构赋能系统），申报通的操作记录及其数据可以作为合同执行情况的依据。</div>
            <div>（二）如政沣公司未按时开通账号给您，应全额退还已收取的账号费。</div>
            <div>（三）因不可抗力造成本合同无法履行时，双方互不承担责任。不可抗力包括国家政策文件发生调整，或发生地震、洪水、火灾、瘟疫、战争、暴乱等人力不可控情况。</div>
          </div>
          <div class="flex col al-start test">
            <div style="font-weight: bold;">第八条&nbsp;&nbsp;争议处理</div>
            <div>双方在合同执行与合作过程中，如发生争议，应先尽量友好协商解决，如协商不成，可向佛山市人民法院提起诉讼。</div>
          </div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'orgApplyAgreement'
};
</script>

<style scoped>
@import '~@/assets/public/style/agreement.css';

.right-area-container {
  height: 960px;
}
.right-area {
  padding: 0 63px 0 61px;
  height: 800px;
  overflow: auto;
}
.desc {
  z-index: 0;
  position: relative;
  height: 160px;
  border-bottom: 1px solid #E3E3E3;
  background-color: #EFF5FC;
}
.desc .left {
  position: absolute;
  top: 63px;
  left: 30px;
}
.desc .left .left-text {
  font-size: 32px;
  font-style: italic;
  color: #303233;
}
.desc .left .left-bar {
  z-index: -1;
  position: absolute;
  top: 30px;
  left: 0;
  width: 320px;
  height: 8px;
  background: #DDEAFF;
}
.desc .right {
  position: absolute;
  display: flex;
  top: 33px;
  right: 85px;
}
.desc .right .img-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 28px;
}
.desc .right .img-item span{
  margin-top: 10px;
  font-size: 14px;
  color: #6A81A8;
}
.desc .back-img {
  z-index: -1;
  position: absolute;
  bottom: 0;
  right: 0;
}
.test {
  border:0px solid red;
  width: 100%;
  font-size: 14px;
  color: #585858;
}
/*滚动条样式设置*/
.right-area::-webkit-scrollbar {
  width: 6px;
  height: 400px;
}
.right-area::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #ECECEC;
}
</style>
