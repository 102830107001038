<template>
  <div class="apply">
<!--    步骤条区域-->
    <org-apply-steps :activeStep="stepOne"></org-apply-steps>
<!--    协议区域-->
    <div class="agree-text">
      <org-apply-agreement></org-apply-agreement>
    </div>
    <!--    同意按钮-->
    <div class="btnbox">
      <button class="agree-bth"
              @click="goToNext">同意，去申请</button>
    </div>
  </div>
</template>

<script>
import orgApplyAgreement from './orgApplyAgreement';
import orgApplySteps from './orgApplySteps';
export default {
  name: 'orgApply',
  components: {
    orgApplyAgreement,
    orgApplySteps
  },
  data () {
    return {
      stepOne: 0
    };
  },
  methods: {
    goToNext () {
      this.stepOne = 1;
      this.$router.replace('/orgapplysubmit');
    }
  }
};
</script>

<style scoped>
.agree-text {
  margin-top: 40px;
  height: 960px;
  border: 1px solid #E3E3E3;
  border-radius: 5px;
}
.btnbox {
  margin-top: 60px;
  display: flex;
  width: 100%;
  justify-content: center;
}
.agree-bth {
  width: 160px;
  height: 46px;
  background: #156ED0;
  border-radius: 3px;
  border: none;
  font-size: 16px;
  font-weight: bold;
  color: #FFFFFF;
  cursor: pointer;
}
</style>
